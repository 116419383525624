import { mapGetters } from 'vuex';
import { FEATURE_FLAG, SWITCH_FLAG } from '@/util/const';

export default {
  data: () => ({
    FEATURE_FLAG,
    SWITCH_FLAG
  }),
  computed: {
    ...mapGetters({
      featureSwitchFlags: 'featureFlags/items'
    }),
    featureFlags() {
      return this.featureSwitchFlags.flags;
    },
    switches() {
      return this.featureSwitchFlags.switches;
    }
  }
};
