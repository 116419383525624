<template lang="pug">
  .journey-assign-candidates
    app-dialog(v-model="showing" title="Assign candidate to journey" @click:outside="clear")
      template(#dialog-action)
        slot(name="activator" :open="open")
          app-button(outlined :disabled="disabled" @click.native="open") {{ activatorText }}
      template(#dialog-body)
        journeysList(
          :selectedCity="selectedCity"
          :cities="cities"
          :groupedJourneys="groupedJourneys"
          :selectedJourney="selectedJourney"
          :loading="loading"
          @needToUpdate="needToUpdateData"
          @update:city="selectCity"
          @select:journey="assignJourney"
          @enter:end="loadMore"
        )
          template(#top)
            select-date-widget(:value="selectedDate" @input="selectDate")
            .separator
      template(#dialog-footer)
        app-button(color="#f4f7f9" @click.native="clear" hide-shadow).mr-3
          span.text-label Cancel
        app-button(color="primary" @click.native="submit") Assign
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import { LOAD_DEFAULT_CITY_ID } from '@/util/const.js'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin.js'
import JourneyGrouped from '@/app/admin/modules/course_day/core/courseDayJourneyGrouped'
import CandidatesService from "@/app/admin/modules/course_day/core/candidates.new.service"
import apiCandidatesActions from "@/services/api/api-candidates-actions"
import PaginationModel from '@/models/paginationModel'
import Intersect from 'vue-intersect'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  name: 'JourneyAssignCandidates',

  mixins: [showingMixin, errorsMixin, featureFlagsMixin],

  props: {
    disabled: Boolean,
    activeItems: Array,
    course: Object,
    activatorText: { type: String,  default: 'Assign to Journey' },
    courseDayID: { type: Number, default: null }
  },

  data: () => ({
    loading: false,
    selectedJourney: null,
    selectedDate: null,
    selectedCity: {},
    groupedJourneys: [],
    LOAD_DEFAULT_CITY_ID,
    pagination: {}
  }),

  computed: {
    ...mapGetters({
      _cities: 'crmCourseCities/items',
      journeys: 'crmJourneys/items'
    }),

    cities() {
      return this._cities ? this._cities : []
    },

    courseCity() {
      if (!this.course) return ''
      else return this.course.city
    },

    courseDate() {
      if (!this.course) return ''
      else return this.course.date
    }
  },

  methods: {
    ...mapActions({
      loadJourneys: 'crmJourneys/list',
      loadJourneysMore: 'crmJourneys/loadMore'
    }),

    async selectCity(city, load = true) {
      this.selectedCity = city
      if (load) await this.load()
    },

    async selectDate(date, load = true) {
      this.selectedDate = date
      if (load) await this.load()
    },

    async load() {
      this.groupedJourneys = []
      this.pagination = new PaginationModel({ page: 1, size: 20 })
      await this.loadJourneysData()
    },

    async loadJourneysData() {
      await this.loadJourneysList()
      this.groupedJourneys = new JourneyGrouped().groupJourneys(this.journeys)
    },

    async loadJourneysList() {
      try {
        this.loading = true
        let query = {
          city: this.selectedCity.ID,
          date: this.selectedDate,
          page: this.pagination.page,
          size: this.pagination.size
        }
        let res = await this.loadJourneys(query)
        this.pagination = new PaginationModel({
          ...res,
          page: res.page,
          size: res.size
        })
        this.loading = false
        return res
      } catch (error) {
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async loadMore() {
      try {
        if(this.loading || this.pagination.isLastPage) return
        this.loading = true
        this.pagination.loadMore()
        await this.loadJourneysMore({
          city: this.selectedCity.ID,
          date: this.selectedDate,
          page: this.pagination.page,
          size: this.pagination.size
        })
        this.groupedJourneys = new JourneyGrouped().groupJourneys(this.journeys)
      } catch (error) {
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally { this.loading  = false}
    },

    async assignJourney(payload) {
      this.selectedJourney = payload
    },

    async submit() {
      if (this.loading) return
      if (!this.selectedJourney) return this.$notify({text: 'Please, select course journey', type: 'error'})
      await this.assignToJourney()
    },

    async assignToJourney() {
      try {
        this.loading = true
        let _ids =  this.activeItems.map(item => item.ID)
        let res = await new CandidatesService(apiCandidatesActions).assignJourneyBulk({
          ids: _ids,
          course_day_id: this.courseDayID, // for new courses
          journey: this.selectedJourney.id,
        })
        this.$notify({text: 'Candidates assigned', type: 'success'})
        this.clear()
        this.loading = false
        this.$emit('updated')
        return res
      } catch (error) {
        console.log(error)
        this.loading = false
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          showErrorModal: false,
        });
      }
    },

    async needToUpdateData(journey) {
      if (journey) {
        let selectedItem = this.groupedJourneys.find(item => item.journeyID === journey.id)

        if (!selectedItem) return
        selectedItem.journeyName = journey.internal_name
        for (let field in selectedItem.items[0]) {
          if (field in journey) {
            selectedItem.items[0][field] = journey[field]
          }
        }
        return this.$emit('needToUpdate')
      }
      await this.loadJourneysData()
      this.$emit('needToUpdate')
    },

    open() {
      this.updateShowing(true)
    },

    clear() {
      this.selectedJourney = null
      this.selectedDate = null
      this.updateShowing(false)
    },

    prefill() {
      if (this.courseDate) this.selectDate(this.courseDate, false)
      //
      let cityID = this.LOAD_DEFAULT_CITY_ID
      if (this.courseCity) cityID = this.courseCity
      this.selectCity(this.cities[cityID])
    }
  },

  watch: {
    showing: {
      handler: function () {
        if (!this.showing) {
          this.clear()
          return
        }
        if (!this.cities.length) return
        this.prefill()
      }
    }
  },

  components: {
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    journeysList: () => import('../journey/JourneysList.vue'),
    appSelect: () => import('@/components/global/actions/BaseSelect.vue'),
    selectDateWidget: () => import('@/components/widget/SelectDate.vue'),
    Intersect
  }
}
</script>
